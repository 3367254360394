<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')" :maxWidth="'100hv'">
      <template v-slot:title>
        <div class="d-flex">
          <span> Настройка пользователей на объектах </span>
          <v-btn-toggle v-model="typeBy" mandatory borderless dense @change="selectedRows = []">
            <v-btn value="user" color="#d0bf9c">
              <v-icon> fas fa-user </v-icon>
            </v-btn>
            <v-btn value="object" class="ml-1" color="#d0bf9c">
              <v-icon> fas fa-building </v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>
      </template>
      <v-tabs-items v-model="typeBy" class="transparent" touchless>
        <v-tab-item :style="{ height: height + 'px' }" :id="`user`">
          <v-card>
            <v-card-text style="overflow: auto" :style="{ height: height / 2 - 10 + 'px' }" class="pa-0">
              <a-table-f-data2
                v-if="true"
                ref="table"
                api="/manager/users/select"
                :dataTable="userList"
                :model="modelUser"
                :useQuery="false"
                :defaults="{ sort: { key: 'id', order: 'DESC' } }"
                :selectedRows="selectedRows"
                @loadData="selectedRows = []"
                @click="
                  counter += 1;
                  onDetectClick($event);
                "
              />
            </v-card-text>
          </v-card>
          <portal-target name="v-add-del" />
        </v-tab-item>
        <v-tab-item :style="{ height: height + 'px' }" :id="`object`">
          <v-card-text style="overflow: auto" :style="{ height: height / 2 - 10 + 'px' }" class="pa-0">
            <a-table-f-api
              v-if="true"
              ref="table"
              api="/mechti/objects"
              :model="modelObject"
              :useQuery="false"
              :defaults="{ sort: { key: 'id', order: 'DESC' }, where: { status: { condition: 'not in', value: [2, -3] } } }"
              :selectedRows="selectedRows"
              @click="
                counter += 1;
                onDetectClick($event);
              "
          /></v-card-text>
          <portal-target name="v-add-del" class="dddd" />
        </v-tab-item>
      </v-tabs-items>
      <portal to="v-add-del">
        <v-row>
          <v-col cols="12" sm="6">
            <material-card color="secondary">
              <template v-slot:heading>
                <div class="px-3 py-1">Не подключено</div>
              </template>
              <v-card-text style="height1: 50vh; overflow: auto" :style="{ height: height / 2 - 64 + 'px' }" class="pa-0">
                <v-chip v-for="el in getListOff" :key="el.value" class="ma-1" small @click="onAdd(el.value)">
                  {{ el.text }}
                </v-chip></v-card-text
              >
            </material-card>
          </v-col>
          <v-col cols="12" sm="6">
            <material-card color="secondary">
              <template v-slot:heading>
                <div class="px-3 py-1">Подключено</div>
              </template>
              <v-card-text style="height1: 50vh; overflow: auto" :style="{ height: height / 2 - 64 + 'px' }" class="pa-0">
                <v-chip v-for="el in getListOn" :key="el.value" class="ma-1" close small @click:close="onDel(el.value)">
                  {{ el.text }}
                </v-chip></v-card-text
              >
            </material-card>
          </v-col>
        </v-row>
      </portal>
      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
  </div>
</template>

<script>
import { getAccess, doubleClickDetect } from "../../components/mixings";

export default {
  mixins: [getAccess, doubleClickDetect],
  props: {
    value: Boolean,
  },
  data() {
    return {
      height: this.$root.windowSize.contentHeight - 16,
      panel: this.task ? null : 0,
      modelUser: [
        { name: "id", title: "#", type: "number", isKey: true },
        { name: "name", title: "Название", type: "string", width: 500, sortable: true },
      ],
      modelObject: [
        { name: "id", title: "#", type: "number" },
        { name: "name", title: "Название", type: "string", width: 500, sortable: true },
        { name: "status", title: "Статус", type: "select", options: "status_object", width: 100, sortable: true, style: "white-space: normal;" },
      ],
      saving: false,
      selectedRows: [],
      userList: [],
      typeBy: "user",
      byUser: {},
      byObject: {},
      user_id: null,
      isChanged: false,
      loaded: false,
      users: this.$root.dirs["user"].multiSort("name"),
      objects: this.$root.dirs["object"].multiSort("name"),
      currentAccess: [],
    };
  },
  computed: {
    getListOn() {
      let res = [];
      if (this.typeBy == "user") {
        const l = this.byUser?.[this.selectedRows[0]] || [];
        l.forEach((el) => {
          res.push({ value: el, text: this.getName(el, "object") });
        });
      }
      if (this.typeBy == "object") {
        const l = this.byObject?.[this.selectedRows[0]] || [];
        l.forEach((el) => {
          res.push({ value: el, text: this.getName(el, "user") });
        });
      }
      return res;
    },

    getListOff() {
      let res = [];
      if (!this.selectedRows.length) return res;
      if (this.typeBy == "user") {
        console.log("obj", this.objects);
        const l = this.byUser?.[this.selectedRows[0]] || [];
        res = this.objects.filter((el) => {
          return !l.includes(el.value) && ![2, -3].includes(el.status);
        });
      }
      if (this.typeBy == "object") {
        const l = this.byObject?.[this.selectedRows[0]] || [];
        res = this.users.filter((el) => {
          return !l.includes(el.value);
        });
      }
      return res;
    },
  },
  watch: {
    value() {
      if (this.value) {
      }
    },
  },
  async created() {
    await this.fitchData();
    this.loaded = true;
  },
  methods: {
    onSingleClick(d) {
      this.currentAccess = this.byUser[d.row.id];
    },
    getName(id, type = "") {
      let name = "ошибка";
      if (type == "user") {
        let list = this.users.find((el) => {
          return el.value == id;
        });
        name = list?.value ? list.text : "Не определено #" + id;
      } else if (type == "object") {
        let list = this.objects.find((el) => {
          return el.value == id;
        });
        name = list?.value ? list.text : "Не определено #" + id;
      }
      return name;
    },
    async onAdd(el) {
      let user_id;
      let object_id;
      if (this.typeBy == "user") {
        user_id = this.selectedRows[0];
        object_id = el;
      }
      if (this.typeBy == "object") {
        object_id = this.selectedRows[0];
        user_id = el;
      }
      if (!object_id || !user_id) return;
      try {
        const resp = await this.$axios.post("/mechti/objects/access_user", { object_id, user_id });
        if (!(resp.data.status == "ok")) {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка при  добавлении....",
          });
          return;
        }
        this.byObject[object_id] = [...(this.byObject?.[object_id] || []), user_id];
        this.byUser[user_id] = [...(this.byUser?.[user_id] || []), object_id];
        this.$root.$emit("show-info", {
          type: "info",
          text: "Добавлен пользователь в объект..",
        });
      } catch (error) {
        console.log(error);
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при добавлении....",
        });
        return;
      }
    },
    async onDel(el) {
      let user_id;
      let object_id;
      if (this.typeBy == "user") {
        user_id = this.selectedRows[0];
        object_id = el;
      }
      if (this.typeBy == "object") {
        object_id = this.selectedRows[0];
        user_id = el;
      }
      if (!object_id || !user_id) return;
      let ok = await this.$refs.confirmDialog.show({
        title: "Удаление",
        message: [
          `Вы действительно хотите удалить пользователя [${this.getName(user_id, "user")}]?`,
          `из списка сотрудников на объекта [${this.getName(object_id, "object")}]`,
        ],
        okButton: "Да",
        cancelButton: "Нет",
      });
      if (!ok) return;
      try {
        const resp = await this.$axios.delete("/mechti/objects/access_user", { data: { object_id, user_id } });
        if (!(resp.data.status == "ok")) {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка при удалении....",
          });
          return;
        }
        this.$root.$emit("show-info", {
          type: "info",
          text: "Удален пользователь из объекта..",
        });
        this.byObject[object_id] = [
          ...(this.byObject?.[object_id] || []).filter((el) => {
            return el !== user_id;
          }),
        ];
        this.byUser[user_id] = [
          ...(this.byUser?.[user_id] || []).filter((el) => {
            return el !== object_id;
          }),
        ];
      } catch (error) {
        console.log(error);
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при удалении....",
        });
        return;
      }
    },

    async fitchData() {
      this.userList = [];
      const resp = await this.$axios.post("/accounting/records/report-sql", {
        q: `SELECT * from objects_access`,
      });
      if (!(resp.data.status == "ok")) {
        this.$root.$emit("show-info", {
          text: "Ошибка....",
        });
        return;
      }
      let byUser = {};
      let byObject = {};
      let data = resp.data.data;
      data.forEach((el) => {
        if (!byUser.hasOwnProperty(el.user_id)) byUser[el.user_id] = [];
        if (!byObject.hasOwnProperty(el.object_id)) byObject[el.object_id] = [];

        byUser[el.user_id].push(el.object_id);
        byObject[el.object_id].push(el.user_id);
      });
      let list = [];
      this.users.forEach((u) => {
        list.push({ id: u.value, name: u.text, status: u.status });
      });
      console.log("users", this.users);
      this.userList = list;
      this.byUser = byUser;
      this.byObject = byObject;
      console.log("fitch", byUser);
      this.loaded = true;
    },
    async submit_() {},
  },
};
</script>
<style lang="sass"></style>
